// custom typefaces
import "typeface-montserrat"
import "typeface-lato"
import "lazysizes"
window.lazySizesConfig = {
	addClasses: true
}

export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(
	  `Teleidoscope has updated. ` +
		`Reload to display the latest version?`
	)
	if (answer === true) {
	  window.location.reload()
	}
  }
import React, { useState, useEffect } from "react"

const IFrame = ({
  stop,
  start,
  idName,
  videoSrcURL,
  videoTitle,
  ratio,
  animate,
  popout,
}) => {
  const [expand, setExpand] = useState(null)

  const viewVideo = () => {
    setExpand(!expand)
  }
  
  useEffect(() => {
    if (expand === false) {
      stop(idName)
    }
    if (expand === true){
      start(idName)
    }
  })

  // adds support to open menu with keyboard navigation
  const buttonPress = event => {
    const key = event.keyCode
    if (key === 13) {
      event.preventDefault()
      return viewVideo()
    }
  }
  return popout ? (
    <div
      data-popout={expand}
      onClick={viewVideo}
      role="button"
      tabIndex="0"
      onKeyDown={buttonPress}
      className="popup-iframe"
      aria-label="Expand video"
    >
      {/* <div onClick={viewVideo} className="close-btn">x</div> &origin=https://teleidoscope.com/*/}
      <div className="vid-wrapper">
        <div
          onClick={viewVideo}
          role="button"
          tabIndex="0"
          onKeyDown={buttonPress}
          className="click-expand"
          aria-label="Close video"
        ></div>
        <div className="vimeo-wrapper" style={{ paddingBottom: ratio }}>
          <iframe
            id={"youtube-video-"+idName}
            style={{ marginBottom: "0" }}
            // data-src={`${videoSrcURL}${
            //   videoSrcURL.includes("youtube")
            //     ? "?enablejsapi=1&controls=0&modestbranding=1/"
            //     : "&quality=720p"
            // }`}
            src={`${videoSrcURL}${
              videoSrcURL.includes("youtube")
                ? "?enablejsapi=1&controls=0&modestbranding=1"
                : "&quality=720p"
            }`}
            className={`video-frame ${animate ? " animate" : ""}`}
            title={videoTitle}
            frameBorder="0"
            allow="autoplay;"
            allowFullScreen
            data-expand={`${animate ? "-140" : "-140"}`}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="vimeo-wrapper" style={{ paddingBottom: ratio }}>
      <iframe
        style={{ marginBottom: "0" }}
        data-src={`${videoSrcURL}${
          videoSrcURL.includes("youtube") ? "" : "&quality=720p"
        }`}
        // src={`${videoSrcURL}${
        //   videoSrcURL.includes("youtube") ? "" : "&quality=720p"
        // }`}
        className={`lazyload ${animate ? " animate" : ""}`}
        title={videoTitle}
        frameBorder="0"
        allow="autoplay;"
        allowFullScreen
        data-expand={`${animate ? "-140" : "-140"}`}
      />
    </div>
  )
}
export default IFrame
